<template>
  <div class="cost">
    <Header
      back="返回"
      title="知识产权"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="专利列表"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <h1>基本信息</h1>
        <el-form-item
          label="企业名称"
          prop="company_name"
          :rules="[{ required: true, message: '企业名称不能为空' }]"
        >
          <el-autocomplete
            class="inline-input"
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入企业名称关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select v-model="form.business_type" placeholder="请选择业务类型">
            <el-option label="新申请" :value="1">新申请</el-option>
            <el-option label="托管" :value="2">托管</el-option>
            <el-option label="变更" :value="3">变更</el-option>
            <el-option label="驳回重新提交" :value="4">驳回重新提交</el-option>
            <el-option label="非正常提交" :value="5">非正常提交</el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          style="width:48%"
          prop="patent_name"
          label="专利名称"
          :rules="[{ required: true, message: '专利名称不能为空' }]"
        >
          <el-input v-model="form.patent_name"></el-input>
        </el-form-item>
        <el-form-item
          label="专利类型"
          prop="patent_type"
          :rules="[{ required: true, message: '专利类型不能为空' }]"
        >
          <el-select v-model="form.patent_type" placeholder="请选择专利类型">
            <el-option label="发明专利" :value="1">发明专利</el-option>
            <el-option label="实用新型" :value="2">实用新型</el-option>
            <el-option label="外观设计" :value="3">外观设计</el-option>
            <el-option label="植物新品种" :value="4">植物新品种</el-option>
            <el-option label="国家新药" :value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" :value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" :value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" :value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" :value="9">其中：国防专利</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专利类型（内部）">
          <el-input v-model="form.patent_type_internal"></el-input>
        </el-form-item>
        <el-form-item label="内部编号">
          <el-input v-model="form.internal_code"></el-input>
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker
            type="date"
            placeholder="选择申请日期"
            v-model="form.app_date"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="申请号" prop="app_code" :rules="[{ required: true, message: '申请号不能为空' }]">
          <el-input v-model="form.app_code"></el-input>
        </el-form-item>
        <el-form-item label="客户经理">
          <el-input v-model="form.custom_manager"></el-input>
        </el-form-item>
        <el-form-item label="工程师">
          <el-input v-model="form.engineer"></el-input>
        </el-form-item>
        <el-form-item label="流程">
          <el-input v-model="form.process"></el-input>
        </el-form-item>
        <el-form-item label="专利状态" prop="status" :rules="[{ required: true, message: '申请号不能为空' }]">
          <el-select v-model="form.status" placeholder="请选择专利状态">
            <el-option label="新申请" :value="1">新申请</el-option>
            <el-option label="审查中" :value="2">审查中</el-option>
            <el-option label="审查合格" :value="3">审查合格</el-option>
            <el-option label="发明实审" :value="4">发明实审</el-option>
            <el-option label="授权" :value="5">授权</el-option>
            <el-option label="驳回" :value="6">驳回</el-option>
            <el-option label="撤回" :value="7">撤回</el-option>
            <el-option label="专利权维持" :value="8">专利权维持</el-option>
            <el-option label="专利权失效" :value="9">专利权失效</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专利状态（内部）">
          <el-input v-model="form.patent_status_internal"></el-input>
        </el-form-item>
        <el-form-item label="专利补写">
          <el-autocomplete
            v-model="form.state"
            :fetch-suggestions="querySearchAsyncText"
            placeholder="请输入需要补写的关键词进行搜索查找"
            @select="handleSelectText"
          ></el-autocomplete>
        </el-form-item>
        <div>
          <el-form-item label="下证情况">
            <el-date-picker
              type="date"
              placeholder="选择下证日期"
              v-model="form.certificate_date"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="证书分发情况">
            <el-input v-model="form.certificate_situation"></el-input>
          </el-form-item>
          <el-form-item label="专利补助">
            <el-input v-model="form.subsidy"></el-input>
          </el-form-item>
        </div>
        <div class="clear"></div>
        <h1>专利费用</h1>
        <el-form-item label="申请费（元）">
          <el-input v-model="form.app_fee"></el-input>
        </el-form-item>
        <el-form-item label="公开费（元）">
          <el-input v-model="form.publish_fee"></el-input>
        </el-form-item>
        <el-form-item label="实审费（元）">
          <el-input v-model="form.examination_fee"></el-input>
        </el-form-item>
        <el-form-item label="授权费（元）">
          <el-input v-model="form.authorize_fee"></el-input>
        </el-form-item>
        <el-form-item label="其他费（元）">
          <el-input v-model="form.other_fee"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <div class="ImportTop">
          <el-upload
            class="upload-demo"
            v-model="form.file_name"
            ref="imgupload"
            action="string"
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
          >
            <el-button type="primary" class="ChooseFile">请选择文件上传</el-button>
          </el-upload>
          <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
          <el-link
            v-model="form.file_id"
            v-if="this.form.file_id"
            :href="userSite + this.form.file_id"
            target="_blank"
          >查看附件</el-link>
        </div>
        <h1>专利年费</h1>
        <el-table :data="form.annual_fee" style="width: 100%" :key="Math.random()">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                v-if="form.annual_fee.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="年份（N）">
            <template slot-scope="scope">
              <el-input v-model="scope.row.year"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="费用（元）">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fee"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="附件">
            <template slot-scope="scope">
              <el-upload
                class="upload-demo"
                ref="imgupload"
                action="string"
                :name="'' + scope.$index"
                :http-request="Uploadfile"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList"
              >
                <el-button type="primary" class="fileUpload">上传</el-button>
              </el-upload>
              <el-link v-if="scope.row.file_id" :href="userSite + scope.row.url" target="_blank">
                <el-button class="edit" size="mini">查看</el-button>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      userSite: site.userSite,
      labelPosition: "top",
      form: {
        company_name: "",
        business_type: "",
        patent_name: "",
        patent_type: "",
        patent_type_internal: "",
        internal_code: "",
        app_date: "",
        app_code: "",
        custom_manager: "",
        engineer: "",
        process: "",
        status: "",
        patent_status_internal: "",
        certificate_date: "",
        certificate_situation: "",
        subsidy: "",
        app_fee: "",
        publish_fee: "",
        examination_fee: "",
        authorize_fee: "",
        other_fee: "",
        annual_fee: [],
        patent_id: "",
        file_name: "",
        file_id: "",
        com_id: "",
        supplement_patent_id: '',
        state: '',
      },
      file_id: "",
      fileList: [],
      formRule: {},
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    this.axios
      .get("/api/patent/view_project_patent", {
        params: {
          id: this.meber_id,
        },
      })
      .then((res) => {
        if (res.data) (this.form = []), (this.form = res.data);
      });
  },
  methods: {
    querySearchAsyncText (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        console.log(queryString)
        this.axios
          .get("/api/patent/select_company_patent", {
            params: {
              com_id: this.form.com_id,
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  supplement_patent_id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              this.$message.warning(res.message)
              cb(results);
            }
          });
      }
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    handleSelectText (item) {
      this.form.supplement_patent_id = item.id;
      this.form.state = item.value;
    },
    httpRequest (param) {
      console.log(param);
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("type", "2");
      this.upload("/api/patent/upload_file", form).then((res) => {
        console.log(res);
        this.form.file_name = res.data.name;
        this.form.url = res.data.url;
        this.form.file_id = res.data.id;
      });
    },
    Uploadfile (param) {
      let file = param.file;
      let cindex = param.filename;
      const form = new FormData();
      form.append("file", file);
      form.append("type", "3");
      console.log(param);
      this.upload("/api/patent/upload_file", form).then((res) => {
        console.log(res);
        this.form.annual_fee[cindex].url = res.data.url;
        this.form.annual_fee[cindex].file_name = res.data.name;
        this.form.annual_fee[cindex].file_id = res.data.id;
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.form.patent_id = this.meber_id;
          this.btnLoading = true
          this.axios
            .post("/api/patent/store_project_patent", this.form)
            .then((res) => {
              console.log(res);
              this.$router.push("/patent/project");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("请完善信息");
        }
      });
    },
    addLine () {
      //添加行数
      this.form.annual_fee.push({
        year: "",
        fee: "",
        url: "",
      });
    },
    handleDelete (index) {
      //删除行数
      this.form.annual_fee.splice(index, 1);
    },
  },
};
</script>

<style scoped="scoped">
.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.fileUpload {
  background: #f2f3f9 !important;
  border-radius: 2px;
  color: #0f38ff !important;
  border: none;
  font-weight: bold;
  padding: 7px 15px;
  font-size: 12px;
  margin-right: 20px;
}

.el-upload-list__item-name {
  margin-top: 20px;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.ImportTop span {
  color: #a6abc7;
}
.el-select {
  width: 100%;
}
</style>
